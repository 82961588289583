import { Overlay } from '@angular/cdk/overlay';
import { Component, OnDestroy, OnInit, Signal, computed, effect } from '@angular/core';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MAT_SELECT_SCROLL_STRATEGY } from '@angular/material/select';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { DataManagement } from 'app/api';
import { DialogBuilderComponent } from 'app/core/dialogBuilder/dialog-builder.component';
import { OrgService } from 'app/modules/org-sys-area/services/org.service';
import { DataManagementTableDetailsFormService } from '../../data-management-table-details-form.service';
import { DataManagementAPIService } from '../../data-management.api.service';

@Component({
    selector: 'app-data-form',
    templateUrl: './data-form.component.html',
    styleUrls: ['./data-form.component.css'],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        FormlyModule,
        FormlyMaterialModule,
    ],
    providers: [
        {
            provide: MAT_SELECT_SCROLL_STRATEGY,
            useFactory: (overlay: Overlay) => () => overlay.scrollStrategies.reposition(),
            deps: [Overlay],
        },
    ],
})
export class DataFormComponent
    extends DialogBuilderComponent<DataManagement>
    implements OnInit, OnDestroy
{
    // form variables
    form = new FormGroup({});
    fields: Signal<FormlyFieldConfig[]> = computed(() => {
        return this._dataDetailsFormService.tableDataFormFields;
    });

    constructor(
        private _dataService: DataManagementAPIService,
        private _dataDetailsFormService: DataManagementTableDetailsFormService,
        private organisationCmpService: OrgService
    ) {
        super();
        this._dataDetailsFormService.newEntry = true;

        effect(() => {
            let code = this.executeActionSignal();
            if (code && code === 'save') {
                this.createNewTable();
            }
        });

        effect(() => {
            if (this.fields().length) {
                this.form.enable();
            }
        }, {});

        // effect(() => {
        //     if (this.form && this.fields && this.componentData && this.componentData.model) {
        //         Object.keys(this.componentData.model).forEach(key => {
        //             this.form.get(key)?.disable();
        //         });
        //     }
        // });
    }

    override ngOnInit(): void {
        super.ngOnInit();
        this._dataDetailsFormService.originalFormValue = this.componentModel;
    }

    ngOnDestroy(): void {
        this._dataDetailsFormService.newEntry = false;
    }

    createNewTable() {
        if (this.form.status === 'INVALID') {
            this.form.markAllAsTouched();
            return;
        }

        let data = { ...this.form.value, id: '', tasks: [], fields: [] };
        if (this.componentData && this.componentData.model) {
            Object.keys(this.componentData.model).forEach(
                key => (data[key] = this.componentData.model[key])
            );
        }
        data['organisation'] = this.organisationCmpService.globallySelectedOrganisationId();
        this._dataService.saveEntry(data);
        this.closeDialog();
    }
}
